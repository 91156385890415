#Sidebar {
    width: 340px;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    padding-top: 10px;
    background: white;
    z-index: 110;
    transition: 0.5s;
    margin: 0;
}

#CloseArrow {
    /* font-weight: bold; */
    float: right;
    padding: 0 12px 0 0;
    height: 40px;
    cursor: pointer;
    text-align: left;
    width: 91%;
    display:flex;
    flex-direction:row;
    justify-content: space-between;
    
}

#OpenArrow {
    /* font-weight: bold; */
    color: black;
    text-align: right;
    position: fixed;
    left: 5px;
    top: 0px;
    height: 30px;
    padding: 0 12px 0 0;
 
    cursor: pointer;
    padding-top: 12px;;
    z-index: 100;

    
  
}

#SearchDiv {
    width: 100%;
}

#SearchInput {
    width: 100%;
    height: 40px;
    font-size: 16px;
    border: none;
    background:#f2f6f7;
    margin: 0;
 
    padding:  8px;
    padding-left:  20px;
    box-sizing: border-box; 
}

#SearchInput:focus {
    outline: none;
}

#SearchResults {
    height: 90%;
    overflow: scroll;
}

.sidebar-title {
    position: fixed;
    height: 40px;
    padding: 20px;
    font-size: 28px;
    font-weight: bold;
 
}


.prompt-icon {
    width: 16%;
    width: 40px;
    height: 40px;
    float:left;
    font-size: 30px;
    text-align: center;
    /* border: solid 0.2px #e6e6e6; */
    border-radius: 5px;
    margin-right:5px;
   
}

.search-right-side {
    float: left;
    text-align: center;
    width: 22%;
    padding-left: 10px;
}

.search-left-side {
    float: left;
    width: 60%;
}

.search-result {
    padding: 6px;
    background: #f7fcff;
    margin: 10px;
 
    margin-bottom: 5px;
    margin-top: 5px;
}

.search-add-button {
    margin-top: 0px;
    width: 100%;
    color: white;
    background: black;
    border:none;
    border-radius: 4px;
    padding: 8px;
 
}
 

.search-result-title {
    font-weight: bold;
    font-size: 18px;
}

.search-result-description {
    font-size: 12px;
}

.prompt-detail-button {
    font-weight: bold;
    font-size: 12px;
    cursor: pointer;
    background: none;
    border: none;
    padding: none;
    margin: none;
    margin-top: 6px;

}

.prompt-info {
    font-size: 16px;
    margin-bottom: 12px;
}

.description {
    margin-bottom: 12px;
}

.detail-settings {
    background: #e9eef0;
    padding: 10px;
    margin-bottom: 12px;
}

.copy-prompt {
    padding: 5px;
    padding-bottom: 0;
    font-size: 22px;
    color: white;
    background: #212526;  
    text-align: right;
    cursor: pointer;
}

.prompt-display {
    color: white;
    background: #212526;
    padding: 20px;
    padding-top: 4px;
    margin-bottom: 12px;
    white-space: pre-wrap;
}

.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: white;
 
    z-index: 100;
  }

.fade-in {
	opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 2s;
}

@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}