body {
    /* background: #fafafa; */
    /* background: #f2f2f2; */
    background: #edf2f5;
}

.button {
    cursor: pointer;
}

#Menu {
    position: absolute;
    color: black;
    top: 0;
    width: 100%;
    height: 30px;
    padding: 8px;
    padding-right: 18px;
    box-sizing: border-box;
    z-index: 1;
  }

#MainWindow {
    margin-top: 45px;
    margin-left: 0px;
    margin-bottom: 100px;
}

#SaveForm {
    margin-top: 20px;
    padding: 20px;
}

#Workspace {
    width: 800px;
    min-height: 40px;
    margin: 20px auto; 

    border-radius: 10px;
    background: black;
}


#PromptWindow {
    width: 800px;
    height: 440px;
    background: white;
    text-align: center;
    padding: 40px 0 40px 0;
    margin-bottom: 40px;

}

#PromptWindow textarea {
    font-size: 18px;
    font-family: 'Arial';
    width: 94%;
    text-align: left;
    height: 90%;
    resize: none;
    border: none;
     
}


#PromptWindow textarea:focus {
    outline: none;
}

#ChatWindow {
    min-height: 300px;
    background: #3f4447;
    padding: 20px;
}

#ChatMessages {
    background: white;
    height: 280px;
    margin: auto;
    padding: 10px;
    overflow: scroll;
}

#ChatInput {
    font-size: 18px;
    background: white;
    
    margin: auto;
    margin-top: 20px;
    width: 100%;
    border: none;
    padding: 12px;
    box-sizing: border-box; 
}

#ChatInput:focus {
    outline: none;
}

#SubmitBar {
    width: 800px;
    margin: auto;
    text-align: right;
 
}

#QAWindow {
    background: #39424a;
    padding: 30px;
    /* border-radius: 12px 12px 0 0; */
}

#QAresponse {
    color: white;
    font-size: 24px;
    min-height: 100px;
}

#QAinput {
    font-size: 16px;
    color: black;
    background: white;
    margin-top: 18px;
    height: 22px;
    width: 98%;
    padding: 12px;
    margin:auto;
}

#QAinput:focus {
    outline: none;
}

#StatsWindow {
    color: white;
    padding: 20px;
    background: black;
    border-radius: 0 0 12px 12px;
}

#ModeSelector {
    float: left;
    display: inline;
    padding-top: 4px;
}

.account-button {
    border: none;
    font-size: 16px;
    cursor: pointer;
}

.mode-select {
    font-size: 16px;
    padding: 8px;
    margin-right: 8px;
}

.mode-select:focus {
    outline: none;
}

.stat-display {
    margin-bottom: 6px;
}

.stat {
    color: #30a9ff;
}

.message {
    width: 100%;
    clear: both;
}

.sender-friend {
    text-align: left;
}

.sender-you {
    width: 100%;
    text-align: right;
}

.message-friend{
    color: black;
    background: #f5f7f7;
    padding: 10px 20px 10px 20px;
    border-radius: 8px;
    float: left;
    max-width: 60%;
}

.message-you {
    color: white;
    background: #00bfff;
    padding: 10px 20px 10px 20px;
    max-width: 60%;
    float: right;
    border-radius: 8px;

}

.submit-button {
    cursor: pointer;
    font-size: 16px;
    color: white;
    background:#0385ff;
    padding: 14px;
    width: 90px;
    border:none;
    border-radius: 4px;
}

.submit-button:focus {
    outline: none;
}

.download-button {
    opacity: 0.4;
    cursor: pointer;
    font-size: 16px;
    padding: 14px;
    border:none;
    border-radius: 4px;
    margin-right: 12px;
}

.download-button:focus {
    outline: none;
}

.save-options-button {
    width: 100px;
    padding: 10px;
    opacity: 0.6;
}

#SaveForm input {
    font-family: 'Raleway';
    font-size: 16px;
    padding: 8px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    
}

#SaveForm textarea {
    padding: 8px;
    font-family: 'Raleway';
    font-size: 16px;
    width: 100%;
    height: 200px;
}

.detail-input {
    /* font-size: 18px;
    padding: 18px;
    background: black; */
    
}

.menu-tab {
    width: 800px;
    height: 10px;
    text-align: right;
    padding: 0;
    margin: auto;
}

.menu-icon {
    cursor: pointer;
    font-size: 22px;
    float:right;
}

.div_line {
    width: 25px;
    height: 2px;
    background-color: black;
    margin: 4px 0 0 0;
     
}



.SettingsBar {
    width: 800px;
    color: white;
    background: black;
    border-radius: 12px 12px 0 0;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
 
}

.first-column {
    width: 30%;
    padding: 10px 20px 10px 30px;
 
}

.second-column {
    width: 30%;
    padding: 20px 20px 0 0;
}

.settings-input {
    width: 100px;
    margin-right: 5px;
 
}

.settings-label {
 
    margin-bottom: 8px;
}

.engine-select {
    color: #30a9ff;
    background: #34383b;
    font-size: 24px;
    padding: 14px;
    font-weight: 800;
    
}

.settings-number-large {
    width: 50px;
    font-size: 18px;
    padding: 4px;
    color: #30a9ff;
    background: #34383b;
    border: none;
    border-radius: 4px;
    padding: 8px;
}

.setting {
    width: 100%;
}

.settings-number {
    width: 50px;
    font-size: 16px;
    padding: 6px;
    color: #30a9ff;
    background: #34383b;
    border: none;
    border-radius: 4px;
    padding: 6px;
    margin-bottom: 12px;
}

.number-label {
    margin-left: 6px;
}

.text-setting {
    width: 200px;
    font-size: 16px;
    padding: 6px;
    color: #30a9ff;
    background: #34383b;
    border: none;
    border-radius: 4px;
    padding: 6px;
    margin-bottom: 12px;
}

.key-box {
    height: 30px;
    background: black;
    margin-left: 16px;
 
}

.key-box-inner {
    height: 55px;
    padding: 0 10px 0 10px;
    background: black;
}

.key-button {
    color: white;
    background: black;
    border: gray 1px solid;
    padding: 8px;
    border-radius: 4px;
    
}

.key-button:focus {
    outline: none;
}

#Loading {
    display: none;
    width: 65px;
    padding-top: 5px;
    padding-left: 25px;
    float: right;
 
    text-align: right;
    
    /* width: 100%;
 
    text-align: center;
    margin-top: -95px;
    clear: both;
    background: none; */

 
}

 
.loader {
    text-align: center;
    padding: none;
    border: 4px solid #f3f3f3; /* Light grey */
    border-top: 4px solid #292c2f; /* Blue */
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 2s linear infinite;
 
 
  }
  
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}